<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.pic') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="pic.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.userId') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="pic.userId"
                  v-validate="'required'"
                  data-vv-as="User Id"
                  name="User Id"
                  :class="{'is-invalid': errors.has('User Id') }"
                  
                />
                <div
                  v-show="errors.first('User Id')"
                  class="invalid-feedback"
                >{{ errors.first('User Id') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.tugas') }}:</label>
              <div class="col-lg-6">
                <tugas-select
                  v-model="pic.tugasId"
                  :validation="'required'"
                  :disabled="pic.userId != null"
                  :value-label="'id'"
                ></tugas-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
              :disabled="dataEdit"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PIC from "../../../model/pic-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const PicRepository = RepositoryFactory.get("pic");
import TugasSelect from "./../../_select/Tugas.vue";
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    TugasSelect
  },
  data() {
    return {
      pic: new PIC()
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit
    }),
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNew',
      updateById: 'MasterStore/updateById'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    updateData: function () {
      if (this.dataEdit) {
        this.pic = Object.assign({}, this.dataEdit);
      }
    },
    submitData: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.pic.id) {
            this.updateById({ repository: PicRepository.updatePic, data: this.pic });
          } else {
            this.createNew({ repository: PicRepository.createPic, data: this.pic });
          }
          return;
        }
      });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    this.updateData();
  }
}
</script>
